import {lotteryIndex} from '../ejs/big/vote/bigConfirm/js/commonfunctions.js';

const pageType = {
  bigConfirm: ["/big/vote/bigConfirm/"], // ページタイプ "bigConfirm" に対応するパス
};

const ratForm = document.querySelector("#ratForm");

// 現在のページのパスを取得
const currentPath = window.location.pathname;

// ページタイプを判定する関数
function getPageType(path) {
  for (const [type, paths] of Object.entries(pageType)) {
    if (paths.includes(path)) {
      return type; // 一致するページタイプを返す
    }
  }
  return null; // 一致しない場合は null を返す
}

// 現在のページタイプを取得
const currentPageType = getPageType(currentPath);

if (currentPageType) {
  // ratFormが存在する場合に処理を加える
  // ページタイプごとの特定の処理
  if (currentPageType === "bigConfirm") {
    bigConfirm();
  }

  function bigConfirm() {
    const ratForm = document.querySelector("#ratForm");
  
    const orderList = document.createElement('input');
    const maker = document.createElement('input');
    const voteElement = [
      document.querySelector(".orderContent._big .orderContent__subtotal span"),
      document.querySelector(".orderContent._megabig .orderContent__subtotal span"),
      document.querySelector(".orderContent._big100yen .orderContent__subtotal span"),
      document.querySelector(".orderContent._big1000 .orderContent__subtotal span"),
      document.querySelector(".orderContent._minibig .orderContent__subtotal span")
    ];
    const voteCount = voteElement.map((element) => {
      if (element) {
        const match = element.textContent.match(/円×(\d+)口/);
        return match ? parseInt(match[1], 10) : 0; // マッチした場合は数値に変換、しなかった場合は 0
      }
      return 0; // element が null または undefined の場合
    });
    let couponFlag = 0;
    let pointFlag = 0;
  
    const availablePoint = parseInt(document.querySelector(".usePointModal__balanceLabel[data-available-points]").dataset.availablePoints);
    const Rcash = parseInt(document.querySelector(".usePointModal__balanceLabel[data-cache-price]").dataset.cachePrice);
    const json = JSON.parse(document.querySelector('#couponJson > script[type="application/json"]').textContent);
    const coupons = json.coupons;
    const totalVotePrice = parseInt(document.querySelector('.orderDetail__detailTitle[data-total-vote-price]').dataset.totalVotePrice.replace(/\D/g, ''));
    const orderLotteryArray = json.orderLottery;
    const couponMainteFlag = document.querySelector("[data-coupon-maintenance-flag=true]") ? 1 : 0;
    const pointMainteFlag = document.querySelector("[data-point-maintenance-flag=true]") ? 1 : 0;
    let availableCount = 0;
    let notAvailableCount = 0;
  
    // ポイントステータス確認
    if ((availablePoint + Rcash) >= 50) {
      pointFlag = 1;
    }
    if (pointMainteFlag) {
      pointFlag = 3;
    }
  
    // クーポンステータス確認
    coupons.forEach((coupon, index) => {
      const availableFlag = coupon.requirementValue <= totalVotePrice && orderLotteryArray[lotteryIndex(coupon.itemId)] > 0 ? true : false;
      if (availableFlag) {
        availableCount++;
      } else {
        notAvailableCount++;
      }
    });
  
    if (couponMainteFlag) {
      couponFlag = 3;
    } else {
      if (availableCount) {
        couponFlag = 1;
      } else if (notAvailableCount) {
        couponFlag = 2;
      }
    }
  
    // ratFormにデータを追加
    if (ratForm) {
      orderList.type = 'hidden';
      orderList.name = 'rat';
      orderList.id = 'ratOrderList';
      orderList.value = `BIG_${voteCount[0]}_${voteCount[1]}_${voteCount[2]}_${voteCount[3]}_${voteCount[4]}`;
  
      maker.type = 'hidden';
      maker.name = 'rat';
      maker.id = 'ratMaker';
      maker.value = `${pointFlag}_${couponFlag}`;
      ratForm.appendChild(orderList);
      ratForm.appendChild(maker);
    }
  }
}