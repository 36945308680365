export const getScrollBarWidth = () => {
  const flag = window.getComputedStyle(document.body).getPropertyValue('overflow') === 'hidden';

  var b = document.body.clientWidth,a;
  if(flag){
    document.body.style.position = 'absolute';
    document.body.style.overflow = 'auto'
    a = document.body.clientWidth;
    document.body.style.position = '';
    document.body.style.overflow = ''
  }else{
    document.body.style.overflow = 'hidden'
    a = document.body.clientWidth;
    document.body.style.overflow = ''
  }
  return Math.abs(a - b);
};

export function handleScrollBarWidth(flag,rootElement){
  const scrollbarWidth = getScrollBarWidth();
  const targets = [
    document.body,
    ...document.querySelectorAll('.rp-modal-add-marginRight')
  ];
  if(rootElement){targets.push(rootElement)};

  targets.forEach((el)=>{
    if(flag){
      el.style.width = `calc(100% - ${scrollbarWidth}px)`;
    }else{
      el.style.width = ``;
    }
  })
}

export function calcCouponPrice(usedCoupons, coupons) {
  if(!usedCoupons){
    return 0;
  }
  let totalDiscount = 0;
  // 使用済みクーポンをループ
  usedCoupons.forEach(usedCoupon => {
      // 使用済みクーポンの情報を取得
      const coupon = coupons.find(c => c.couponCd === usedCoupon.couponCd);

      if (coupon) {
          // クーポンの割引額を加算
          totalDiscount += parseFloat(coupon.discountFactor);
      }
  });

  return totalDiscount;
}

export function couponText(usedCoupons) {
  const lotteryMap = {
    "09": "BIG",
    "14": "MEGA BIG",
    "13": "100円BIG",
    "11": "BIG1000",
    "10": "mini BIG"
  };// BIG MEGA 100円 BIG1000 mini
  const valueMap = {
  "09": 300,
  "14": 300,
  "13": 100,
  "11": 200,
  "10": 200
};// BIG MEGA 100円 BIG1000 mini
  var textArray=[]
  if(!usedCoupons){
    return ["利用なし"];
  }
  usedCoupons.forEach((coupon)=>{
    textArray.push(`${lotteryMap[coupon.itemId]} <span>${parseInt(parseInt(coupon.discountFactor)/valueMap[coupon.itemId]).toLocaleString()}</span>口(<span>${parseInt(coupon.discountFactor).toLocaleString()}</span>円)分`)
  })
  return textArray;
}
export function lotteryIndex(lotteryId) {
  const indexMap = {
    "09": 0,
    "14": 1,
    "13": 2,
    "11": 3,
    "10": 4
  };
  
  return indexMap[lotteryId];
}
/**
 * 指定したkey名のカスタムイベントを送信する
 * @method send
 * @param  {string} key   カスタムイベント名
 * @param  {object} value データ
 */
export const dispatch = (key, value = {}) => {
  let ev;
  try {
    ev = new CustomEvent(key, { detail: value });
  } catch (e) { // for IE
    ev = document.createEvent('CustomEvent');
    ev.initCustomEvent(key, false, false, value);
  }
  document.dispatchEvent(ev);
};

/**
 * カスタムイベントのリスナーを登録する
 * @method listen
 * @param  {string}   key      カスタムイベント名
 * @param  {Function} callback イベントリスナーから呼ばれる関数
 */
export const listen = (key, callback) => {
  document.addEventListener(key, callback);
};

export const minPoint=50;

// 支払い方法のコードをその名称に変換して戻す
export function getPaymentMethodsById(paymentMethodId) {
  const paymentMethods = [
    { id: 'rbank', name: '楽天銀行', type: 'bank' },
    { id: 'kc_card', name: '楽天カード', type: 'card' },
    { id: 'jcb_card', name: 'JCBカード', type: 'card' },
  ];
  return paymentMethods.find((v) => v.id === paymentMethodId);
}

export const apiTestingFlag = (()=>{
  const host = window.location.host;
  switch(true){
    case /dev.com/.test(host):
    case /localhost/.test(host):
    case /winner.toto.rakuten.co.jp:8080/.test(host):// localhost server 
      return true;
    default:
      return false;
  }
})();

export function convertDateTime(input) {
  // 入力値をDateオブジェクトに変換
  const date = new Date(input);
  var output="";
  // 入力値がすでにJSTであるかを判定
  if (!isNaN(date.getTime()) && input.includes("T")) {
    // 入力値にタイムゾーン情報が含まれている場合（例: 2024-12-30T23:59:59.000+0900）
    const jstOffset = 9 * 60; // JSTはUTC+9時間
    const utc = date.getTime() + date.getTimezoneOffset() * 60 * 1000; // UTC時間を取得
    const jstDate = new Date(utc + jstOffset * 60 * 1000); // JST時間を計算
    // 年、月、日、時間、分を取得
    const year = jstDate.getFullYear();
    const month = String(jstDate.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため+1
    const day = String(jstDate.getDate()).padStart(2, '0');
    const hours = String(jstDate.getHours()).padStart(2, '0');
    const minutes = String(jstDate.getMinutes()).padStart(2, '0');

    // 出力形式に変換
    output = `${year}/${month}/${day} ${hours}:${minutes}`;
  } else {
    // 入力値がすでにJSTである場合（例: 2025/01/15 23:59）
    const jstDate = new Date(input);

    // 年、月、日、時間、分を取得
    const year = jstDate.getFullYear();
    const month = String(jstDate.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため+1
    const day = String(jstDate.getDate()).padStart(2, '0');
    const hours = String(jstDate.getHours()).padStart(2, '0');
    const minutes = String(jstDate.getMinutes()).padStart(2, '0');
    output=`${year}/${month}/${day} ${hours}:${minutes}`;
    // 出力形式に変換
  }
  return output;
}


/**
 * 指定された親フォームに新しいinput要素を追加する関数
 * @param {HTMLFormElement} parentForm - 親フォームの要素
 * @param {string} name - input要素のname属性の値
 * @param {string} value - input要素のvalue属性の値
 */
export function createFormEle(parentForm, name, value) {
  // 新しいinput要素を作成
  const inputElement = document.createElement('input');
  inputElement.type = 'hidden';
  inputElement.id=name;
  inputElement.name = name;
  inputElement.value = value;

  // 親フォームにinput要素を追加
  parentForm.appendChild(inputElement);
}

export function addCommasToNumbers(element) {
  if (!element) {
    return;
  }
  const skipIds = ['couponJson'];
  if (element.nodeType === Node.TEXT_NODE) {
    if (skipIds.includes(element.id)) {
      return;
    }
    // テキストノードの場合、条件に合う数字にカンマを追加
    element.textContent = element.textContent.replace(/(\d{1,3}(?:,\d{3})*|\d+)(?:(?=円)|(?=ポイント)|(?!\S))/g, match => {
      // カンマを除去してから数値に変換し、再度カンマを追加
      return parseInt(match.replace(/,/g, '')).toLocaleString();
    });
  } else if (element.nodeType === Node.ELEMENT_NODE) {
    // 要素ノードの場合、子ノードを再帰的に処理
    if (skipIds.includes(element.id)) {
      return;
    }
    Array.from(element.childNodes).forEach(child => addCommasToNumbers(child));
  }
}

export function formatTextWithComma(text) {
  // 「合計xxxxx円以上」の形式を探してカンマを付ける
  text = text.replace(/(合計)(\d+)(円以上)/g, (match, prefix, number, suffix) => {
      const formattedNumber = parseInt(number, 10).toLocaleString();
      return `${prefix}${formattedNumber}${suffix}`;
  });

  // 「xxxxx口以上」の形式を探してカンマを付ける
  text = text.replace(/(\d+)(口以上)/g, (match, number, suffix) => {
      const formattedNumber = parseInt(number, 10).toLocaleString();
      return `${formattedNumber}${suffix}`;
  });

  // 「合計 」の後ろにスペースが入っている場合、そのスペースを削除
  text = text.replace(/(合計)\s+/g, '合計');

  return text;
}

export function initializeOrderDetailMenu(){
  const orderDetail=document.querySelector(".orderDetail__detail");
  const wrongStyleElement=document.querySelector(".orderDetail__detailData.-fw-bold.-red");
  if(wrongStyleElement){
    wrongStyleElement.classList.remove("-fw-bold","-red")
  }
  if(orderDetail){
    const title=document.querySelectorAll(".orderDetail__detailTitle");
    const replacement=new Map([
      ["支払方法","支払い方法"],
      ["支払金額","支払い金額"],
      ["売り場","売場"]
    ]);
    title.forEach((ele)=>{
      replacement.forEach((newText,oldText)=>{
        if(ele.innerHTML.includes(oldText)){
          ele.innerHTML=ele.innerHTML.replace(oldText,newText);
        }
      })
    })
    orderDetail.classList.add("-initialized")
  }
}